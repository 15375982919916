import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

var lang = navigator.language.slice(0,2);

if (lang != 'nl' && lang != 'en') {
  lang = 'en';
}

const routes = [
  {
    path: '/',
    redirect: '/' + lang + '/',
  },
  {
    path: '/:locale(en|nl)/',
    name: 'Questionnaire',
    meta: { layout: 'web', requiresAuth: false },
    component: () => import(/* webpackChunkName: "questionnaire" */ '../views/Questionnaire.vue')
  },
  {
    path: '/:locale(en|nl)/result/:uuid',
    name: 'Result',
    meta: { layout: 'web', requiresAuth: false },
    component: () => import(/* webpackChunkName: "result" */ '../views/Result.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    meta: { layout: 'empty', requiresAuth: false },
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next('/inloggen');
  }

  next();
})

export default router
