<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-light static-top bg-dark">
            <div class="container">
                <a href="#" title="GGZ Christoph" rel="home">
                    <img src="https://www.ggzchristoph.nl/wp-content/uploads/2019/04/GGZ-Logo.png" alt="GGZ Christoph">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                </button>
                <div class="right">
                    <b-form-select @change="setLocale" v-model="selectedLang" :options="langs">
                        <!-- <img :src="`/flags/` + lang.iso + `.svg`" width="25" class="mr-2" /> -->
                    </b-form-select>
                </div>
            </div>
        </nav>
        <main>
            <slot/>
        </main>
    </div>
</template>

<script>

export default ({
    data() {
        return {
            langs: [
				{ value: 'en', text: 'English' },
				{ value: 'nl', text: 'Nederlands' },
            ],
            selectedLang: null,
        }
    },
    methods: {
		setLocale(locale) {
			let uri = this.$route.fullPath;
			uri = uri.replace(this.$route.params.locale, locale);

			window.location = uri;
		},
    },
    created() {
		this.selectedLang = this.$i18n.locale;
	}
})
</script>


<style scoped>
    main{
        margin-top:50px;
        margin-bottom:50px;
    }
</style>
<style>
    h1{
        margin-bottom:25px !important;
    }
    legend{
        font-size:130% !important;
        margin-bottom:1.5rem;
    }
    .form-group{
        margin-bottom:2rem;
    }
    .custom-radio,
    .custom-checkbox{
        margin-bottom:0.5rem;
    }
</style>