import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
library.add(faUserSecret)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Portal from './layouts/Portal.vue'
import Empty from './layouts/Empty.vue'
import Web from './layouts/Web.vue'

Vue.component('portal-layout', Portal);
Vue.component('empty-layout', Empty);
Vue.component('web-layout', Web);

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

Vue.config.productionTip = false

require('@/assets/theme/plugins/global/plugins.bundle.css');
require('@/assets/theme/css/style.bundle.css');

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
